<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="row col-md-12 col-sm-12 col-12">
      <SimpleOPList
      :title="'Importação Marítima'"
      :rowsData="operationalImpo"
      :rowsMedia="operationalImpoMedia[0]"
      ></SimpleOPList>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <SimpleOPList
      :title="'Exportação Marítima'"
      :rowsData="operationalExpo"
      :rowsMedia="operationalExpoMedia[0]"
      ></SimpleOPList>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <SimpleOPList
      :title="'Aéreo'"
      :rowsData="operationalAereo"
      :rowsMedia="operationalAereoMedia[0]"
      ></SimpleOPList>
    </div>
    <div class="row col-md-12 col-sm-12 col-12">
      <SimpleOPList
      :title="'Rodoviário'"
      :rowsData="operationalRodoviario"
      :rowsMedia="operationalRodoviarioMedia[0]"
      ></SimpleOPList>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import SimpleOPList from '@/components/Kpi/SimpleOPList.vue'

export default {
  name: 'OperationalAnalysts',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.operatinalList') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      operationalList: [],
      operationalImpo: [],
      operationalExpo: [],
      operationalAereo: [],
      operationalRodoviario: [],
      operationalListMedia: [],
      operationalImpoMedia: [],
      operationalExpoMedia: [],
      operationalAereoMedia: [],
      operationalRodoviarioMedia: []
    }
  },
  components: {
    Loading,
    SimpleOPList
  },
  beforeMount () {

  },
  mounted () {
    this.getAnalystList()
  },
  methods: {
    getAnalystList () {
      this.isLoading = true

      KpiService.getOperationalList().then(res => {
        this.operationalList = res.data.data
        this.operationalListMedia = res.data.media

        this.operationalImpo = this.operationalList.filter((item) => {
          return item.setor_analista === 'Importação Marítima'
        })

        this.operationalExpo = this.operationalList.filter((item) => {
          return item.setor_analista === 'Exportação Marítima'
        })

        this.operationalAereo = this.operationalList.filter((item) => {
          return item.setor_analista === 'Aéreo'
        })

        this.operationalRodoviario = this.operationalList.filter((item) => {
          return item.setor_analista === 'Rodoviário'
        })

        this.operationalImpoMedia = this.operationalListMedia.filter((item) => {
          return item.setor_analista === 'Importação Marítima'
        })

        this.operationalExpoMedia = this.operationalListMedia.filter((item) => {
          return item.setor_analista === 'Exportação Marítima'
        })

        this.operationalAereoMedia = this.operationalListMedia.filter((item) => {
          return item.setor_analista === 'Aéreo'
        })

        this.operationalRodoviarioMedia = this.operationalListMedia.filter((item) => {
          return item.setor_analista === 'Rodoviário'
        })

        this.isLoading = false
      })
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style scoped>

</style>
